nav {
	position: fixed;
	width: 100%;
	z-index: 100;

	&:before {
		content: '';
	    position: absolute;
	    height: 100vh;
	    width: 150px;
	    right: 0;
	    background: linear-gradient(to left, rgba(255, 255, 255, 0.7), rgba(255, 255, 275, 0.1) 85%, rgba(255, 255, 255, 0));
	    display: block;
	    transform: translateX(100%);
	    transition: .5s;
	    opacity: 0;
	}

	&.active:before {
		transform: translateX(0);
		opacity: 1;
	}

	.hamburger {
		width: 30px;
		height: 30px;
	    position: absolute;
	    top: $contentPadding;
	    right: $contentPadding;
	    cursor: pointer;
	    transition: .2s;

		&:before, &:after {
			content: '';
			display: block;
			width: 30px;
			height: 2px;
			background: #996617;
			position: absolute;
			transition: .2s;
			transition-timing-function: ease-in-out;
			top: 7px;
			opacity: 0;
		}

		&.full {

			&:before {
				top: 3px;
				opacity: 1;
			}

			&:after {
				top: 11px;
				opacity: 1;
			}
		}

		&.open {

			&:before, &:after {
				// background: none;
				top: 7px;
			}
		}
	}

	.menu {
	    width: 120px;
	    position: absolute;
	    right: $contentPadding;
	    top: 2.5*$contentPadding;
	    text-align: right;
	    transform: translateX(70px);
	    transition: .2s;

	    a {
	    	display: block;
		    font-size: 1.25rem; //20px
		    line-height: 1.5;
	    }

	    &.active {
			transform: translateX(0px);
	    }
	}
}
