.gig {
    padding: $contentPadding 0;
    border-top: 1px solid $lightOpacity;

    &:last-child {
		border-bottom: 1px solid $lightOpacity;    	
    }

    .date {
		width: 120px;
		position: relative;
		display: inline-block;
    }

    .tofb {
        display: block; 
        margin-left: 120px
    }

    @include below($s) {

        .tofb {
            margin-left: 0;
        }
    };
}