body {
	background: $light;
}

.wrapper {
    position: relative;
    margin: 0 auto;
    z-index: 10;
}

.fullwidth-bg {
	background-size: cover;
	background-position: center 17%;
	position: relative;
	padding: 3*$contentPadding 0;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: $dark;
		opacity: .6;
	}
}

.holder {
	position: relative;
	width: 560px;
	max-width: $contentMaxWidth;
	margin: 4*$contentPadding auto;

	&.wide {
		width: 900px;
	}

	@include below($s) {
		padding: 0 .5*$contentPadding;

		&.wide {
			padding: 0;
		}
	}
}

.center {
	text-align: center;
}

.content {

	p {
		margin: .5*$contentPadding 0;
	}
	
	.preamble {
		margin: 0 0 $contentPadding;
	}
}

.img-holder {
    width: 130%;
    margin: 1.5*$contentPadding -15%;
	box-shadow: 2px 3px 20px 1px rgba(0, 0, 0, 0.2);
	font-size: 0;
	position: relative;

	img {
		width: 100%;
	}

	@include below($m) {
	    width: 118%;
	    margin: 1.5*$contentPadding -9%;
	}
}

.anchor {
    position: absolute;
    top: -40px;
}