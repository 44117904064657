footer {
	position: relative;
	font-size: 0;
	z-index: 2;

    .holder {
		margin: 4*$contentPadding auto $contentPadding;
		background: white;
		padding: 2*$contentPadding;
    }

    .footer-img {
    	width: 100%;
    	height: auto;
    }

    .footer-content {
	    position: absolute;
	    bottom: 0;
	    width: 100%;
    }

    .can {
		position: absolute;
		width: 160px;
		right: 21px;
    top: -53px;
		filter: grayscale(100%);

		img {
			width: 100%;
		}
    }

    .some {
        margin-top: .5*$contentPadding;

    	svg {
		    width: 50px;
		    height: 50px;
		    display: inline-block;
		    opacity: .6;
		    transition: .2s;
		    margin: .2*$contentPadding;
    	}

    	a:hover svg {
    		opacity: .9;
    	}
	}

    @include below($m) {
		overflow: hidden;

		.holder {
			background-color: transparent;
			margin: 0;
    		padding-top: 2*$contentPadding;
			max-width: 100%;
			width: 100%;
    		background-image: linear-gradient(to bottom, rgba(0,0,0,.6), rgba(0,0,0,.0));
		}

    	.footer-content {
			height: 100%;
    		background-image: none;
    		padding-top: 0;

    		a {
    			color: $light;
    		}
    	}

    	.some {

			svg {
				opacity: 1;

				path,
				polygon {
					fill: $light;
				}
			}
    	}

    	.can {
		    display: none;
    	}
    }
}