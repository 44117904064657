// Fonts
@font-face {
    font-family: 'AbrahamLincolnRegular';
    src: url('../fonts/AbrahamLincolnRegular.woff2') format('woff2'),
        url('../fonts/AbrahamLincolnRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

body {
    font-family: 'AbrahamLincolnRegular';
    // color: $dark;
}

p, .orisi {
    font-family: 'AbrahamLincolnRegular';
    font-size: 1.25rem; //20px
    line-height: 1.5;
}

.x-large {
    font-size: 4rem; //64px
    margin: 0 0 20px 0 !important;;
    line-height: 1.1;

    @include below($s) {
        font-size: 1.75rem; //28px
        line-height: 1.2;   
    }
}

.large {
    font-size: 3rem; //36px
    line-height: 1.1;

    @include below($s) {
        font-size: 1.75rem; //28px
        line-height: 1.2;   
    }
}

.medium {
    font-size: 1.8rem; //28px
    line-height: 1.2;   
}

.small {
    font-size: 1.5rem; //24px
    line-height: 1.25;  
}

.x-small {
    font-size: 1rem; //16px
    line-height: 1.2;   
}

.italic {
    font-style: italic;
}

.white {
    color: $light; 
}

.secondaryfont {
    font-family: 'Abraham';
}

.inline {
    display: inline-block;

    @include below($s) {
        display: block;
    }
}

.border-top {
    position: relative;
    display: inline-block;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
    }

    @include below($s) {
        font-size: 1rem;
        margin-right: $contentPadding;
    }
}

.title {
    text-transform: uppercase;
    position: relative;
    margin: $contentPadding;
}

a {
    text-decoration: none;
    color: $dark;

    &:hover, &:active &:focus {
        text-decoration: underline;
    }
}