.logo {
	position: fixed;
	z-index: 1;
	pointer-events: none;
	width: 200px;
	left: $contentPadding;
	top: $contentPadding;
	opacity: 1;
	transform: scale(1);
	transition: .5s;
	transform-origin: left top;

	#logo_black path {
		fill: #996617;
	}

	svg {
		width: 100%;
	}

	@include below($s) {
		position: absolute;

		#logo_black path {
			fill: $dark;
		}
	}
}