.cover {
	width: 200px;
	height: 200px;
	background-size: cover;
	display: inline-block;
	margin: .5*$contentPadding;
	position: relative;
	transition: .2s;
	filter: grayscale(100%);

	&:hover {
		transform: scale(1.02);
		filter: grayscale(0%);
	}

	&.upcoming {

		.overlay {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba(0,0,0,.5);
			opacity: 0;
			transition: .7s;

			.cover-title {
				@include align-both(absolute);
			}
		}

		&:hover {

			.overlay {
				opacity: 1;
			}
		}
	}

	@include below($m) {
		transform: scale(1.02);
		filter: grayscale(0%);
	}

	@include below($s) {
		width: 140px;
		height: 140px;
	}
}