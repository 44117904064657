.video {
	width: 275px;
	height: 155px;
	background-size: cover;
	display: inline-block;
	margin: .5*$contentPadding;
	position: relative;
	transition: .2s;

	.overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0,0,0,.5);
		opacity: 0;
		transition: .7s;
	}

	.video-title {
		bottom: 10px;
		left: 10px;
		position: absolute;
	}

	&:after {
		content: '';
		@include align-both(absolute);
		width: 50px;
		height: 50px;
		transition: .2s;
		background-image: url('../img/icon_play.svg');
		background-size: cover;
	}

	&:hover {

		&:after {
			background-image: url('../img/icon_play_full.svg');
		}

		.overlay {
			opacity: 1;			
		}

	}

	@include below($l) {
		width: 312px;
		height: 175px;
	}

	@include below($m) {
		
		&:after {
			background-image: url('../img/icon_play_full.svg');
		}

		.overlay {
			opacity: 1;			
		}
	}

	@include below($s) {
		width: 300px;
		height: 170px;
	}
}